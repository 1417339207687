<template>
  <vs-row class="full-height" :style="{ margin: '0px' }">
    <vs-row vs-justify="center">
      <vs-col
        type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="12"
        vs-xs="12"
      >
        <div>
          <div v-html="rawHtml"></div>
        </div>
      </vs-col>
    </vs-row>
  </vs-row>
</template>
<script>
export default {
  name: "details",
  mixins: [],
  props: {
    widgetName: {
      type: String,
      required: true,
    },
    entityName: {
      type: String,
      default: "prop2",
    },

    cassielid: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    rawHtml: "",
  }),

  mounted: function () {
    this.previewLink = process.env.VUE_APP_PREVIEW_BASE_URL;
    this.editLink = "/edit" + "/" + this.widgetName + "/" + this.entityName;
    const baseFormDetailsUrl = this.$apiList.baseFormDetailsUrl;

    let formConfigurationUrl =
        baseFormDetailsUrl + "/" + this.widgetName + "/" + this.entityName;

    let cassieldId = this.cassielid;
    if (this.$route.query.cassielId) {
      cassieldId = this.$route.query.cassielId;
    }

    const token = this.$store.getters["auth/getToken"];
    let headers = {};
    if (token) {
      headers["X-AUTH-TOKEN"] = token;
    }

    let bodyFormData = new FormData();
    bodyFormData.append("cassielId", cassieldId);

    this.$http({
      url: formConfigurationUrl,
      method: "POST",
      data: bodyFormData,
      headers: headers,
    }).then((response) => {
      this.rawHtml = response.data;
      this.$nextTick(function () {});
      this.is_data_fetched = true;
    });
  },
};
</script>
